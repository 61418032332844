$pere-primary-color: #E8A014;
$pere-secondary-color: #18835B;
$pere-dark-color: #2E2A26;
$pere-primary-gradient-color: -webkit-gradient(linear, left bottom, left top, from(#b92944), to(#991e35));
$white-color:#FFF;
$timeslot-bg-color:#35838e;
$rating-color:#E8A014;
$greenColor:#18835B;
$successColor:#18835B;
$errorColor:#A80F34;
$grayColor: #6C6967;
$darkBG:#423F3B;
$grayBG:#EAE9E9;
$lightGray:#D4D4D4;
$lightGray2:#E9E9E9;
$shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
$shadowHover: 0px 4px 8px 0px #e8a014;
$shadow-sm: 0 1px 4px rgba(0, 0, 0, 0.2);
$offwhite:#faecd0;
$inputBG:rgb(255 255 255 / .3);
$inputErrBG:#eecfd6;
$colorYellow:#ffec8b;
$tableHover:#FDF5E7;

// dinning session
$breakfast:#FFEC8B;
$lunch:#AED4C6;
$dinner:#69AE94;

// tiers

$tier1: #FFEC8B;

$tier2: #aed4c6;

$tier3: #69ae94;

$tier4: #429e7c;

$tier5: #18835b;

$tier6: #acdaed;

$tier7: #81cded;

$tier8: #63b9dd;

$tier9: #39aada;

$tier10: #1094cb;
