@import 'variables.scss';

body.dark-theme {


  background: $darkBG;
  color: #FFF;

  header {
    background-color: $darkBG;

    .support-section {
      span {
        color: #fff !important;
      }

      strong {
        color: #fff !important;
      }
    }

    .book-btn {
      color: #FFF !important;
    }

    .mdc-list-item__primary-text {
      color: #FFF !important;
    }

    .mdc-button__label {
      color: #fff !important;
    }
  }

  .user-menu {
    .mdc-list-item__primary-text {
      color: #fff !important;
    }

  }

  .custom-mat-form-field,
  .custom-mat-form-field2 {
    background: $inputBG;

    mat-label {
      color: #FFF !important;
    }
  }

  .mat-mdc-input-element {
    color: #FFF;
    background: none;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-frame,
  .mat-radio-outer-circle {
    border-color: #C0BFBD;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-layout .mat-checkbox-label {
    color: #FFF;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-value {
    color: #FFF;
  }

  .mat-expansion-panel {
    background: $darkBG;
  }

  p {
    color: #FFF;
  }

  .btn-underlined {
    color: #FFF;
  }

  app-top-search .search-dropdown .btn-underlined {
    color: rgba(0, 0, 0, .87) !important;
  }

  .map-btn {
    background: #6c6967;
    color: #FFF;
  }

  .restaurant-card2 {
    background: $pere-dark-color;
    border: 0;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label,
  .mat-mdc-tab-link {
    color: #FFF;
  }

  .mat-mdc-tab-group.mat-primary .mat-ink-bar,
  .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #FFF;
  }

  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(255, 255, 255, .12);
  }

  .phone-number {
    color: #FFF;
  }

  .btn-gray {
    background: $grayColor;
    color: #FFF;
  }

  .mat-mdc-menu-panel {
    background: $pere-dark-color;
  }

  .earnings-item {
    color: #FFF;
  }

  .mat-mdc-menu-item {
    color: #FFF;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-bar {
    background-color: $grayColor;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $offwhite;
  }

  .sticky {
    background: $pere-dark-color;
    box-shadow: 0 0 4px #000;
  }

  .mat-mdc-dialog-container {
    background-color: $pere-dark-color;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: #FFF;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #FFF;
  }

  .mat-mdc-dialog-container {
    color: #FFF;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-placeholder {
    color: #FFF;
  }

  .mat-mdc-unelevated-button.mat-primary.mat-button-disabled,
  .mat-flat-button.mat-accent.mat-button-disabled,
  .mat-flat-button.mat-warn.mat-button-disabled,
  .mat-flat-button.mat-button-disabled.mat-button-disabled,
  .mat-mdc-raised-button.mat-primary.mat-button-disabled,
  .mat-raised-button.mat-accent.mat-button-disabled,
  .mat-raised-button.mat-warn.mat-button-disabled,
  .mat-raised-button.mat-button-disabled.mat-button-disabled,
  .mat-mdc-fab.mat-primary.mat-button-disabled,
  .mat-fab.mat-accent.mat-button-disabled,
  .mat-fab.mat-warn.mat-button-disabled,
  .mat-fab.mat-button-disabled.mat-button-disabled,
  .mat-mdc-mini-fab.mat-primary.mat-button-disabled,
  .mat-mini-fab.mat-accent.mat-button-disabled,
  .mat-mini-fab.mat-warn.mat-button-disabled,
  .mat-mini-fab.mat-button-disabled.mat-button-disabled {
    background-color: $pere-primary-color;
  }

  .dialog-header {
    border-bottom: 1px solid #716f6f;
    color: #FFF;
  }

  .ticket {
    background-color: transparent;
  }

  h2 {
    color: #FFF;
  }

  .search-criteria {
    background-color: transparent;
  }

  .breadcrumb li a {
    color: #FFF !important;
  }

  .tools-cards li a {
    background: $pere-dark-color !important;
    border: 0 !important;

    h4,
    h1 {
      color: #FFF !important;
    }

    p {
      color: #FFF !important;
    }
  }

  h5 {
    color: #FFF;
  }

  .custom-label {
    color: #FFF !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-empty.mat-form-field-label,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    color: rgba(255, 255, 255, .6);
  }

  app-preferences {
    .tools-cards {
      .shadow {
        background-color: $pere-dark-color !important;
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white;
    background: $inputBG;
  }

  /* Font color */
  input.mat-mdc-input-element {
    color: white;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    color: #FFF
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $errorColor;
    background: $inputErrBG;
    border-radius: 7px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $errorColor;
  }

  .copyRights {
    color: #FFF;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $pere-primary-color;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-checkmark-path {
    stroke: $pere-dark-color !important;
  }

  .rest-price-rate {

    li {
      color: $grayColor !important;

      &.highlightened {
        color: $lightGray !important;
      }
    }

  }

  .on-light-theme {
    display: none !important;
  }

  .on-dark-theme {
    display: inline-block !important;
  }

  .login-container {
    background: $darkBG !important;
  }

  .table,
  .table-stripe {
    color: #FFF;

    tr {
      td, th{
        color: #FFF;
      }
    }
  }
  .table tr {
    th, td {
      background-color: $darkBG;
    }
    
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  tr.mat-row,
  tr.mat-footer-row,
  .mat-mdc-paginator,
  .mat-mdc-paginator-page-size .mat-select-trigger,
  .mat-table thead {
    background-color: $darkBG !important;

  }

  .mat-mdc-cell,
  .mat-mdc-footer-cell,
  .mat-mdc-header-cell {
    color: #FFF !important;
    border-bottom-color: #777472 !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-paginator,
  .mat-paginator .mat-button-wrapper {
    color: #FFF !important;
  }

  .mat-mdc-header-cell,
  .mat-expansion-panel-header-title {
    color: #FFF !important;
  }

  .mat-stepper-horizontal {
    background-color: $pere-dark-color !important;
  }

  .mat-step-header .mat-step-label.mat-step-label-active,
  .mat-step-header .mat-step-label {
    color: #FFF !important;
  }

  .hotel-list {
    background-color: $pere-dark-color !important;

    li {
      color: #FFF !important;

      span {
        color: #FFF !important;
      }
    }

    li a:hover {
      background: $darkBG !important;
    }
  }

  .suggested-rest div {
    background-color: $pere-dark-color !important;
  }

  .search-criteria.inline {
    background-color: $pere-dark-color !important;
  }

  table.mat-mdc-table {
    border: none;
  }

  ul.links-list+hr {
    display: none !important;
  }

  .offline-booking-form hr {
    display: none !important;
  }

  .booking-modal-header,
  .miss-restaurant-header {
    background: #1d1a18;
  }

  .suggested-rest {
    border: none;
  }

  .mat-divider {
    border-top-color: $darkBG !important;
  }

  .edit-mode {
    .hover-edit-mode {
      background: $pere-dark-color !important;

      &:hover {
        opacity: 0.9 !important;
      }
    }
  }

  .date-svg,
  .clock-svg {
    g {
      fill: #fff !important;
    }
  }

  .close-svg {
    g {
      stroke: #fff !important;
    }
  }

  .user-svg {
    path {
      fill: #fff !important;
    }
  }

  .clear-btn {
    color: $pere-dark-color !important;
  }

  .logo-container {
    h3 {
      color: #fff !important;
    }
  }

  .filter-bar {
    background: $darkBG !important;
  }

  .sub-companies {
    background: $pere-dark-color;

    .card-container {
      background: $pere-dark-color;
      color: #fff;
    }

    .card-body h3 {
      color: #fff !important;
    }
  }

  .mobile-toolbar {
    background: #fff !important;

    ul {
      li {
        a {
          color: $pere-dark-color !important;
        }
      }
    }
  }

  tr.faded {
    opacity: 1 !important;
    background-color: $pere-dark-color !important;

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-value,
    .mat-cell,
    .pen-commission {
      color: $grayColor !important;
      border-bottom-color: #777472 !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: $grayColor !important;
    }
  }

  .other-users a {
    color: #fff !important;
  }

  .restaurant-card2 {
    box-shadow: $shadow;
  }

  .tools-cards {
    li {
      a {
        box-shadow: $shadow !important;

        &:hover {
          box-shadow: $shadowHover !important;
        }
      }
    }
  }

  input.mat-mdc-input-element::placeholder {
    color: #fff !important;
  }

  textarea.mat-mdc-input-element::placeholder {
    color: #fff !important;
  }

  app-book-online {
    a {
      strong {
        color: #fff !important;
      }
    }
  }

  app-public-settings {
    .card {
      background-color: $pere-dark-color !important;
      border: 0 !important;
      box-shadow: $shadow !important;

      &:hover {
        box-shadow: $shadowHover !important
      }
    }

    input {
      color: #fff;
      background: $inputBG;
    }
  }

  app-public-settings app-fav-list-sort {
    h3 {
      margin-bottom: 30px !important;
    }

    .restaurant-list {
      border: none;

      .restaurant-box {
        background-color: $pere-dark-color !important;
        color: #fff;
      }
    }
  }

  app-public-footer {
    .social-link-container {
      span {
        color: #fff !important;
      }
    }
  }

  app-new-guest-booster {
    .prev-setups {
      background-color: $pere-dark-color;
    }
  }

  .card-body {
    background: $darkBG;
  }


  // payout tree

  app-tree-structure {
    .lvl-1 {
      background: $pere-dark-color;
    }

    .mat-mdc-unelevated-button {
      background: none;
    }

    .lvl-2 {
      p {
        color: $pere-dark-color;
      }
    }
  }

  app-change-password-modal {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field.mat-focused .mat-form-field-ripple {
      background-color: #1b1918 !important;
    }

    .toggle-password-btn {
      color: #FFF;

      &:hover {
        opacity: 0.8;
      }
    }
  }


  app-csv-export {
    button {
      background: none !important;
      color: #fff;
      padding-top: 6px !important;
    }
  }

  app-restaurant-profile {
    .profile-header {
      .main {

        border: 1px solid $darkBG !important;
      }

      .grid>div>div {
        border: 1px solid $darkBG !important;
      }
    }
  }

  app-book-confirmation {
    app-approx-icon {
      g {
        fill: #fff !important;
      }

    }
  }

  .sticky-msg {
    color: $pere-dark-color !important;

    p,
    button {
      color: $pere-dark-color !important;
    }
  }

  tr {

    th,
    td {
      a {
        color: #fff !important;
      }
    }
  }

  tr {
    &:hover {
      background-color: #5b4d35 !important;

    }
  }


  app-edit-company {
    .border {
      background-color: inherit !important;

      h6 {
        color: #fff;
      }
    }
  }

  .loading-placeholder .loader:after {
    background: $pere-dark-color !important;
  }

  .tabs-container {
    .active-tab {
      color: #FFF !important;
      border-bottom: 3px solid #FFF !important;

    }
  }

  .view-options {
    background: $pere-dark-color !important;
    color: #fff;
  }

  app-commission-overview {
    .always-show {
      background-color: #423F3B !important;
      border-left: 0 !important;
      border-right: 0 !important;

      app-tier-icon,
      app-sessions-icon,
      app-spend-icon,
      app-flat-fee-icon,
      app-approx-icon {
        svg {
          g {
            fill: #fff !important;

            path {
              fill: #fff !important;
            }
          }
        }
      }
    }

  }

  .cls-1 {
    fill: #777676;
  }

  .mat-calendar-table {
    tr:hover {
      background-color: inherit !important;
    }
  }

  input:not(.search-input) {
    caret-color: #fff !important
  }

  .mdc-text-field__input {
    color: #fff !important;
  }

  .mat-mdc-select-value {
    color: #fff !important;
  }



  .mat-mdc-dialog-container {
    input {
      // caret-color: #000 !important
    }

    textarea {
      // caret-color: #000 !important
    }

    .mdc-text-field__input {
      // color: #000 !important;
    }

    .mat-mdc-select-value {
      // color: #000 !important;
    }

    .user-svg path {
      // fill: #000 !important;
    }

    .date-svg g {
      // fill: #000 !important;
    }

    .clock-svg g {
      // fill: #000 !important;
    }
  }

  textarea {
    caret-color: #fff !important;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #fff !important;
  }

  .mat-mdc-checkbox .mdc-form-field {
    color: #fff !important;
  }


  .mat-mdc-header-row,
  .mat-mdc-row,
  .mat-mdc-footer-row,
  .mat-mdc-table .mat-mdc-header-cell {
    background: #423F3B !important;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch {
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-color: #fff !important;

  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: $pere-primary-color !important;
    border-width: 2px !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch {
    border-left: none !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: #fff !important;
  }

  .mdc-text-field .mdc-floating-label:not(.mdc-floating-label--float-above) {
    color: #ffffff99 !important;
  }

  .mdc-text-field .mdc-floating-label.mdc-floating-label--float-above {
    color: #fff !important;
  }

  .mat-mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label,
  .mat-mdc-tab-link .mdc-tab__text-label {
    color: #ffffff99 !important;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: #fff !important;
  }

  tr.faded .mat-mdc-select-value,
  tr.faded .mat-mdc-cell,
  tr.faded .pen-commission {
    color: #6C6967 !important;
    border-bottom-color: #777472 !important;
  }

  .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: inherit !important;
  }

  .mat-mdc-header-row {
    background: #423F3B !important;
  }

  app-csv-export span {
    color: #fff !important;
  }

  tr:hover {
    background: none !important;
    background-color: #5b4d35 !important;
  }

  .mat-mdc-paginator-outer-container {
    color: #fff !important;
  }

  .mat-mdc-icon-button .mat-mdc-paginator-icon {
    fill: #fff !important;
  }

  .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    fill: #ffffff1f !important;
  }

  .mdc-dialog__surface {
    background-color: $pere-dark-color !important;
  }

  .mat-mdc-radio-button .mdc-form-field {
    color: #fff !important;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: #C0BFBD !important;
  }

  .mat-mdc-menu-item .mdc-list-item__primary-text,
  .mat-mdc-menu-content .main-service {
    color: #fff !important;

  }

  app-book-offline .mat-mdc-select-value {
    color: #fff !important;
  }

  .sticky-book-form {
    background-color: $darkBG !important;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #d3d3d3 !important;
  }

  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background-color: #a15c1769 !important;
  }

  app-service-payment {
    li {
      color: #fff !important;
    }
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    color: #fff !important;
  }

}
