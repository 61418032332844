 @import '~quill/dist/quill.core.css';
 @import '~quill/dist/quill.bubble.css';
 @import '~quill/dist/quill.snow.css';
 @import "https://fonts.googleapis.com/icon?family=Material+Icons";
 @import "./assets/scss/variables";
 // @import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');
 // @import url('https://use.typekit.net/qgt6zzl.css');

 @font-face {
   font-family: "proxima-nova";
   font-style: normal;
   font-weight: normal;
   src: url("./assets/fonts/proximanova-400.eot");
   src: local("proximanova-400"),
     url("./assets/fonts/proximanova-400.woff") format("woff"),
     url("./assets/fonts/proximanova-400.ttf") format("truetype"),
     url("./assets/fonts/proximanova-400.svg#proximanova-700") format("svg");
 }

 @font-face {
   font-family: "proxima-nova";
   font-style: normal;
   font-weight: bold;
   src: url("./assets/fonts/proximanova-700.eot");
   src: local("proximanova-700"),
     url("./assets/fonts/proximanova-700.woff") format("woff"),
     url("./assets/fonts/proximanova-700.ttf") format("truetype"),
     url("./assets/fonts/proximanova-700.svg#proximanova-700") format("svg");
 }

 /* Change autocomplete styles in WebKit */
 input:-webkit-autofill,
 input:-webkit-autofill:hover,
 input:-webkit-autofill:focus,
 textarea:-webkit-autofill,
 textarea:-webkit-autofill:hover,
 textarea:-webkit-autofill:focus,
 select:-webkit-autofill,
 select:-webkit-autofill:hover,
 select:-webkit-autofill:focus {
   border: 0px solid inherit;
   -webkit-text-fill-color: inherit;
   box-shadow: 0 0 0px 0px transparent inset;
   -webkit-box-shadow: 0 0 0px 0px transparent inset;
   transition: background-color 5000s ease-in-out 0s;
 }

 button,
 a {
   transition: all 0.3s ease 0s;
 }

 a {
   text-decoration: none;
   background-color: transparent;
 }

 button:hover,
 a:hover {
   opacity: 0.8;
   text-decoration: none;
 }

 button:focus,
 a:focus {
   outline: none;
 }

 .plain-btn {
   background: none;
   border: 0;
 }

 .border-right {
   border-right: 1px solid #ccc;
 }

 .offwhite-bg {
   background: #fafafa;
 }

 .gray-bg {
   background: #eaeaea;
 }

 .h-100vh {
   height: 100vh;
 }

 img {
   &:focus {
     border: 0;
     outline: 0;
   }
 }

 .btn-block {
   display: block;
   width: 100%;
 }

 .text-muted {
   color: #afb6bb !important;
 }

 .user-inner-container {
   margin: auto 10%;
   background: #fff;
   padding: 25px;
   box-shadow: 0 0 10px #acacac;
   border-radius: 3px;
   text-align: center;
 }

 // .mat-tab-labels {
 //   justify-content: space-around;
 // }

 td.mat-mdc-cell,
 td.mat-mdc-footer-cell,
 th.mat-mdc-header-cell {
   text-align: left;
   color: inherit;
 }

 *,
 body {
   font-family: "proxima-nova";
 }

 //  .material-icons {
 //   font-family: 'Material Icons' !important;
 //  }
 .mdc-data-table__cell,
 .mdc-data-table__header-cell {
   font-family: "proxima-nova"
 }

 body {
   background: #fff;
   // background: #e5e6e7;
   font-size: 14px !important;
   color: $pere-dark-color;
 }

 ul,
 li {
   list-style: none;
   margin: 0;
   padding: 0;
 }

 button:focus {
   outline: 0;
 }

 .white-btn {
   border: 0;
   background: #fff;
   color: #a5223a;
   padding: 5px 15px;
   text-transform: uppercase;
   font-size: 13px;
   font-weight: bold;
 }

 .text-shadow {
   text-shadow: 0 0 4px #4444448a;
 }

 h1 {
   font-size: 36px;
 }

 h2 {
   font-size: 28px;
   color: #2a2a2a;
 }

 h3 {
   font-size: 24px;
 }

 h4 {
   font-size: 20px;
 }

 h5 {
   font-size: 18px;
   color: #4b4c4d;
 }

 h6 {
   font-size: 16px;
 }

 p {
   font-size: 14px;
   color: #4b4c4d;
 }

 @media (max-width: 500px) {
   h1 {
     font-size: 23px;
   }

   h2 {
     font-size: 20px;
   }
 }

 hr {
   border-color: #ebebeb;
 }

 /************************************* Mobile
div:not(.mobile) .search-bar mat-label {
  color: #FFF;
}

div:not(.mobile) .search-bar .mat-select-value {
  color: rgba(255, 255, 255, 0.87);
}

div:not(.mobile) .search-bar .mat-select-value-text {
  color: #FFF;
}

div:not(.mobile) .search-bar .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}

div:not(.mobile) .search-bar .mat-form-field-appearance-legacy .mat-form-field-underline,
div:not(.mobile) .search-bar .mat-focused .mat-form-field-underline {
  background-color: rgba(255, 255, 255, .8) !important;
}

div:not(.mobile) .search-bar .mat-form-field-label {
  color: #FFF;
}

div:not(.mobile) .search-bar .mat-datepicker-toggle-default-icon {
  color: #FFF;
}

div:not(.mobile) .search-bar .mat-input-element {
  color: #FFF;
}

*******************************************************/
 /* @media(max-width:1023px) {
  .search-bar form {
    display: flex;
  }

  .search-bar form>button {
    height: 30px;
    font-size: 11px;
    line-height: 11px;
    margin-right: auto;
  }

  .search-bar .mat-form-field {
    width: 20%;
    font-size: 11px;
    margin: 0 1rem 0 0 !important;
  }

  .search-bar .mat-form-field:first-child {
    width: 10%;
  }
} */

 .mobile .headerSearch {
   background: #fff;
   padding-top: 20px;
   height: 100vh;
 }

 .mobile .headerSearch form {
   display: flex;
   flex-direction: column;
   align-items: center;
 }

 .mobile .headerSearch form>* {
   width: 85%;
 }

 // .mobile .search-bar {
 //   text-align: center;
 // }

 .mobile .optionBtns {
   margin-bottom: 10px;
 }

 .mobile .filter-bar {
   height: 100vh;
 }

 .mobile .filter-bar mat-form-field {
   width: 85%;
 }

 .filter .tooltip {
   opacity: 1;
   display: none;
 }

 .filter .tooltip.in {
   display: block;
 }

 .mat-mdc-icon-button:focus {
   outline: 0;
   border: 0;
 }

 .mat-mdc-dialog-container {
   position: relative;
 }

 .text-pere {
   color: $pere-primary-color;
 }

 //  .timeslots .slick-prev:before,
 //  .timeslots .slick-next:before {
 // background-image: url("assets/images/arrow-right.svg");
 // background-size: contain;
 // content: "";
 // display: inline-block;
 // width: 15px;
 // height: 15px;
 // background-repeat: no-repeat;
 //  }

 .slick-prev:before {
   transform: rotate(180deg);
 }

 @media (max-width: 700px) {
   .cdk-overlay-pane {
     max-width: 95% !important;
   }

   .mat-mdc-dialog-container {
     max-height: 76vh !important;
   }
 }

 /* range slider */
 .slider.slider-horizontal .slider-track {
   height: 3px;
   margin-top: -9px;
 }

 .slider-selection {
   background: $pere-primary-color;
 }

 // .slider-track-low,
 // .slider-track-high {
 //   background: $pere-primary-color;
 // }

 .slider.slider-horizontal .slider-tick,
 .slider.slider-horizontal .slider-handle {
   margin-left: 0px;
 }

 .slider-handle {
   width: 13px;
   height: 13px;
   background-color: $pere-primary-color;
   background-image: none;
   top: -4px;
 }

 .slider.slider-horizontal {
   vertical-align: bottom;
 }

 .slider .tooltip.top {
   margin-top: -43px;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .langDD .mat.mdc-select-value-text span,
 .langDD .mat-mdc-select-arrow {
   color: #ccc !important;
   font-size: 12px;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .langDD .mat-mdc-select-arrow {
   border-left: 4px solid transparent !important;
   border-right: 4px solid transparent !important;
   border-top: 4px solid !important;
   margin: 5px 3px 0 !important;
 }

 .fixed-spinner {
   position: fixed;
   bottom: 40%;
   left: 55%;
   z-index: 999999999;
   right: 45%;

   &.public {
     bottom: 24%;
     left: 41%;
   }

   &.dashboard {
     top: 43%;
     left: 41%;
   }
 }

 .fixed-spinner-overlay {
   position: fixed;
   bottom: 150px;
   left: 0;
   z-index: 999999999;
   right: auto;
   background: #fff;
   width: 100%;
   height: 100%;
   top: 0;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .cards.in .restaurant-card {
   cursor: pointer;
 }

 .restaurant-info p {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   line-height: normal;
   max-height: 33px;
 }

 .sticky .search-bar {
   padding-top: 10px;
 }

 .mobile-toolbar {
   position: fixed;
   bottom: 1rem;
   left: calc(50% - 75px);
   z-index: 10;
   width: 150px;
   background: $pere-dark-color;
   box-shadow: $shadow;
   border-radius: 3px;
   height: 40px;

   ul {
     display: flex;
     align-items: center;
     justify-content: space-evenly;
     height: 100%;

     li {
       color: $pere-primary-color;
       display: block;
       text-align: center;
       height: 100%;
       line-height: 40px;

       &.separator {
         font-size: 25px;
       }

       a {
         color: #fff !important;
         font-size: 14px;
         cursor: pointer;
         display: block;
         height: 100%;
       }
     }
   }
 }

 .book-form .selected-slot {
   background: #991e35 !important;
   color: #fff !important;
 }

 .call2ActionBtn:disabled {
   color: #ccc;
   cursor: no-drop;
 }

 .slick-slider {
   width: 88%;
   margin: auto;
 }

 .book-form a:not([href]):not([tabindex]) {
   color: #fff !important;
 }

 // .mobile-view .cdk-overlay-pane {
 //   position: absolute;
 //   top: 0;
 //   bottom: 0;
 //   height: 100%;
 // }

 .mobile-view .mat-mdc-dialog-content {
   max-height: 100vh !important;
 }

 @media (min-width: 900px) {
   .book-modal-dialog {
     width: 750px;
   }
 }

 .timeslots .slick-track {
   // height: 36px;
   overflow: hidden;
 }

 .custom-margins {
   margin: 0 5%;
 }

 @media (max-width: 1536px) {
   .custom-margins {
     margin: 0 3%;
   }
 }

 .suggested-rest {
   cursor: pointer;
   transition: all 0.3s ease 0s;
 }

 .suggestions:hover>.suggested-rest {
   opacity: 0.5;
 }

 .suggestions:hover>.suggested-rest:hover {
   opacity: 1;
 }

 .mat-mdc-raised-button .mdc-button__label .mat-mdc-fab.mat-primary,
 .mat-mdc-unelevated-button.mat-primary,
 .mat-mdc-mini-fab.mat-primary,
 .mat-mdc-raised-button.mat-primary {
   background: $pere-primary-color !important;
   color: $pere-dark-color !important;
   min-height: 47px !important;
   border-radius: 4px !important;
 }

 // .mat-fab.mat-warn,
 // .mat-flat-button.mat-warn,
 // .mat-mini-fab.mat-warn,
 // .mat-raised-button.mat-warn {
 //   height: 50px;
 // }

 // .mat-checkbox-checked.mat-accent .mat-checkbox-background,
 // .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
 //   background-color: $pere-primary-color;
 // }

 .mat-mdc-fab.mat-accent,
 .mat-mdc-unelevated-button.mat-accent,
 .mat-mdc-mini-fab.mat-accent,
 .mat-mdc-raised-button.mat-accent {
   background: $pere-secondary-color !important;
   min-height: 47px !important;
   border-radius: 4px !important;
   line-height: 47px !important;

   &.btn-sm {
     min-height: 35px !important;
     line-height: 35px !important;
     min-width: 55px !important;
   }
 }

 .mat-mdc-raised-button.mat-default {
   background: #fff !important;
   color: $pere-dark-color !important;

   &.btn-sm {
     min-height: 35px !important;
     line-height: 35px !important;
     min-width: 55px !important;
   }
 }

 // .mat-checkbox-checked.mat-accent .mat-checkbox-background,
 // .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
 //   background-color: $pere-secondary-color;
 // }

 .ls-1 {
   letter-spacing: 1px;
 }

 .ls-2 {
   letter-spacing: 2px;
 }

 .ls-3 {
   letter-spacing: 3px;
 }

 .ls-4 {
   letter-spacing: 4px;
 }

 .cursor-pointer {
   cursor: pointer;
 }

 .alert {
   border-top: 4px solid;
   border-top-left-radius: 0;
   border-top-right-radius: 0;

   &.alert-danger {
     border-top: 5px solid #92002b !important;
     background-color: #b30038;
     color: #fff;
     border-radius: 0 !important;
   }
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .mat-select-panel-wrap {
   margin-top: 26px;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .mat-mdc-select-arrow {
   background: url("./assets/images/dd-arrow-ico.svg") no-repeat center center;
   width: 19px !important;
   height: 19px !important;
   border: 0 !important;
   //margin-top: ;
 }

 .mat-expansion-indicator {
   background: url("./assets/images/dd-arrow-ico.svg") no-repeat;
   width: 19px;
   height: 19px;
 }

 .mat-expansion-indicator::after {
   margin: 5px;
   color: #b4bcca;
   display: none !important;
 }

 .mat-calendar-body-selected {
   background-color: $pere-primary-color;
 }

 .mat-calendar-body-cell-content {
   border-width: 0 !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
 .mat-checkbox-checked.mat-accent .mat-checkbox-background,
 .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
   background-color: $pere-dark-color;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
 .mat-checkbox-checkmark-path {
   stroke: $pere-primary-color !important;
 }

 // .line-height-#{$name} {
 //   line-height: #{$name}px;
 // }

 input.mat-mdc-input-element {
   line-height: 1;
 }

 /**********
scrollbar
***********/
 /* width */
 ::-webkit-scrollbar {
   width: 10px;
   height: 3px;

   &:hover {
     height: 6px;
   }
 }

 /* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: rgb(207, 207, 207);
 }

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: rgb(117, 117, 117);
 }

 .hideGoogleAutoComplete .pac-container {
   display: none !important;
 }

 .googleAutoCompleteEditMode .pac-container {
   position: fixed !important;
 }

 .lh-30 {
   line-height: 30px;
 }

 .btn {
   &:focus {
     box-shadow: none;
     outline: 0;
   }
 }

 @media print and (min-width: 20px) {
   body {
     background-color: #fff;
   }

   app-header,
   app-footer {
     display: none;
   }

   .no-print,
   .no-print * {
     display: none !important;
   }
 }

 @keyframes fadeOut {
   0% {
     opacity: 0;
     font-size: 1500%;
   }

   50% {
     opacity: 1;
     font-size: 1500%;
   }

   100% {
     opacity: 0;
     font-size: 100%;
   }
 }

 .fade-zoom-animation {
   display: block;
   -webkit-animation: fadeOut 4s;
   animation: fadeOut 4s;
   animation-fill-mode: forwards;
 }

 .mat-mdc-menu-item {
   height: auto !important;
 }

 // .sidebar-toggled {
 //   .dashboard {
 //     width: 100% !important;
 //   }

 //   #accordionSidebar {
 //     position: fixed;
 //     top: 0;
 //     left: 0;
 //     z-index: 999999;
 //   }
 // }

 .slider-horizontal {
   .tooltip.in {
     opacity: 1;
   }
 }

 table.mat-mdc-table {
   box-shadow: none !important;
   border: 1px solid #ccc;
 }

 .filter-bar {
   .slider.slider-horizontal {
     width: 94%;
   }
 }

 .mat-mdc-paginator-container {
   justify-content: flex-start !important;
 }

 .sliding-panel-wrapper>* {
   flex-basis: 300px;
   margin: 10px;
   transition: all 0.4s ease 0s;
 }

 .sidebar-toggled {
   #content-wrapper {
     &::after {
       display: none;
     }
   }
 }

 .user-menu {
   min-width: 185px !important;

   .mat-mdc-menu-item {
     width: 100%;
     height: auto;
     line-height: 30px !important;
   }
 }

 @media (max-width: 600px) {
   // #fc_frame {
   //  display: none;
   // }

   .fixed-spinner {
     left: 38%;
   }
 }

 .text-gray {
   color: $grayColor;
 }

 .bg-gray {
   background-color: $grayBG;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
 .mat-radio-outer-circle,
 .mat-checkbox-frame {
   border-color: $lightGray;
   box-shadow: $shadow-sm;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
 .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
   border-color: $pere-primary-color;
 }

 .mat-expansion-panel-header-description,
 .mat-expansion-indicator::after {
   color: #212529;
 }

 .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle,
 .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
 .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
 .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
   background-color: $pere-primary-color;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
 .mat-slide-toggle-thumb {
   background-color: $lightGray;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
 .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
   background-color: $pere-dark-color;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
 .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
   background-color: $pere-primary-color;
 }

 .mat-mdc-progress-spinner.mat-accent circle,
 .mat-mdc-progress-spinner.mat-accent circle {
   stroke: $pere-primary-color;
 }

 .text-underlined {
   text-decoration: underline !important;
 }

 .border-bottom-pere {
   border-bottom: 1px solid $pere-primary-color;
 }

 .btn-underlined {
   border: 0;
   border-bottom: 1px solid;
   border-color: $pere-primary-color;
   color: $pere-dark-color;
   padding-bottom: 0px;
   margin-bottom: 5px;
   padding-right: 0;
   padding-left: 0;
   border-radius: 0;
   font-weight: bold;
 }

 .mat-mdc-tab-group.mat-primary .mat-ink-bar,
 .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
   background-color: $pere-dark-color;
 }

 .shadow {
   box-shadow: $shadow !important;
 }

 .mat-mdc-button,
 .mat-mdc-icon-button,
 .mat-mdc-outlined-button,
 .mat-mdc-unelevated-button {
   min-height: 47px !important;
 }

 .btn-gray {
   background: $grayBG;
   border: 0 !important;
 }

 .mat-mdc-menu-panel {
   border: 1px solid $pere-primary-color;
 }

 .mat-mdc-dialog-container {
   border: 1px solid $pere-primary-color;
 }

 .mat-mdc-raised-button {
   min-height: 47px !important;
 }

 .copyRights {
   color: $pere-dark-color;
 }

 .on-dark-theme {
   display: none !important;
 }

 @media (min-width: 1600px) {
   .container-xxl {
     max-width: 1500px;
     margin-left: auto !important;
     margin-right: auto !important;
   }
 }

 @media (max-width: 1500px) {
   .container-xxl {
     width: 100%;
     padding-right: 15px;
     padding-left: 15px;
     margin-right: auto;
     margin-left: auto;

     .container-xxl {
       padding: 0 !important;
     }
   }
 }

 .close-modal-btn {
   border: 0;
   background: none;
   position: absolute;
   right: 3px;
   top: 8px;
 }

 .mat-mdc-row:hover {
   background-color: #fdf5e7;
 }

 .admin-page-title {
   font-weight: bold;

   img {
     width: 50px;
     margin-right: 15px;
   }

   @media (max-width: 600px) {
     img {
       width: 35px;
     }
   }
 }

 .mat-mdc-header-cell {
   color: $pere-dark-color;
   font-weight: bold;
 }

 .mat-sort-header-arrow {
   color: $pere-primary-color;
 }

 .booking-modal-header,
 .miss-restaurant-header,
 .filters-header {
   position: relative;
   top: 0;
   max-height: 62px;
   text-align: left;
   background: $pere-dark-color;
   color: #fff;
   left: 0;
   width: 100%;
   display: flex;
   align-items: center;
   padding-left: 15px;

   h5,
   h4,
   h3 {
     margin: 10px 40px 10px 0;
     color: #fff;
   }

   button {
     z-index: 9;
   }
 }

 .fc-widget-open {
   .fresh-chat-btn {
     app-spinner {
       display: none;
     }
   }
 }

 #content-wrapper {
   >#content {
     min-height: calc(100vh + 70px);
   }
 }

 /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
 //  .mat-mdc-unelevated-button.mat-primary.mat-button-disabled,
 //  .mat-mdc-raised-button.mat-primary.mat-button-disabled,
 //  .mat-mdc-fab.mat-primary.mat-button-disabled,
 //  .mat-mdc-mini-fab.mat-primary.mat-button-disabled,
 //  .mat-mdc-raised-button:disabled {

 //    background-color: $pere-primary-color !important;
 //  }

 .mat-mdc-raised-button:disabled {
   opacity: 0.7 !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
 .mat-mdc-unelevated-button.mat-accent.mat-button-disabled,
 .mat-mdc-raised-button.mat-accent.mat-button-disabled,
 .mat-mdc-fab.mat-accent.mat-button-disabled,
 .mat-mdc-mini-fab.mat-accent.mat-button-disabled {
   opacity: 0.7;
   background-color: $pere-secondary-color;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .mat-select-value {
   max-width: none !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 .mat-form-field-appearance-outline .mat-form-field-prefix {
   top: -0.15em !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 .mat-form-field-appearance-outline.mat-form-field-type-mat-select .mat-form-field-suffix {
   top: 0.45em !important;
 }

 .arrow-svg {
   vertical-align: super;
   cursor: pointer;
 }

 .text-underlined-white {
   position: relative;

   &:after {
     content: "";
     background-color: #fff;
     height: 4px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0;
   }
 }

 .text-underlined-pere {
   position: relative;

   &:after {
     content: "";
     background-color: $pere-primary-color;
     height: 4px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0;
   }

   &.sm {
     &:after {
       height: 3px;
       bottom: 0;
     }
   }
 }

 .block {
   .img-container {
     height: 100px;
     overflow: hidden;

     img {
       height: 100%;
     }
   }

   .desc {
     height: 80px;
     overflow: hidden;
   }
 }

 .toast-top-center {
   top: 10px !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .mat-mdc-select-arrow {
   display: none;
 }

 .mat-mdc-paginator {

   /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
   .mat-mdc-select-arrow {
     display: inline-block !important;
     margin: -3px -2px 0 0 !important;
   }
 }

 .mat-expansion-indicator {
   display: none;
 }

 .shrink-2x {
   transform: scale(0.7);
 }

 .mat-calendar-controls {
   .mat-mdc-icon-button {
     min-height: 0px !important;
   }
 }

 //  #fc_frame,
 //  #fc_frame.fc-widget-normal {
 //    position: fixed !important;
 //    top: 50%;
 //    left: 50%;
 //    transform: translate(-50%, -50%);
 //  }

 // @media (max-width:900px) {
 //   #fc_frame,
 //   #fc_frame.fc-widget-normal {
 //     margin-right: 20% !important;
 //   }
 // }

 //  .fc_open,
 //  .fc_frame {
 //    top: 50%;
 //    left: 50%;
 //  }

 //  .chat-bg {
 //    background-color: $darkBG;
 //    height: 100vh;
 //    width: 100%;
 //    z-index: 214748360;
 //    display: none;
 //    position: fixed;
 //    top: 0px;
 //    right: 0px;
 //    opacity: 0.8;
 //  }

 //  .fc-widget-open .chat-bg {
 //    display: block !important;
 //  }

 //  .introjs-nextbutton {
 //    font-weight: 700 !important;
 //  }

 .mat-form-field-appearance-outline.mat-focused {

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-outline-thick {
     color: $pere-dark-color;
   }

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-label {
     color: $pere-dark-color;
   }
 }

 .mat-form-field-appearance-legacy.mat-focused {

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-label {
     color: $pere-dark-color;
   }
 }

 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 .mat-form-field-appearance-outline .mat-form-field-outline-thick {
   color: $pere-primary-color;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 .mat-mdc-form-field.mat-focused .mat-form-field-ripple {
   background-color: $pere-dark-color;
 }

 .cdk-virtual-scroll-spacer {
   z-index: 10000000;
 }

 @supports (-webkit-touch-callout: none) {
   /* CSS specific to iOS devices */
   // .cdk-virtual-scroll-spacer {
   //   height: 100vh !important;
   //   width: 15px !important;
   //   background-color: #ccc;
   // }

   .sort-fav-btn {
     display: none;
   }
 }

 [class$="parentgroup"]+g {
   display: none !important;

   g {
     fill: rgb(255 255 255) !important;
   }
 }

 .mat-step-header .mat-step-icon-selected {
   background-color: $pere-primary-color;
   color: #fff;
 }

 .mat-step-header .mat-step-icon-state-edit {
   background: url('assets/images/check-success-ico.png') no-repeat center;
   background-size: 60% 60%;
   background-color: $pere-dark-color;
 }

 .mat-step-icon .mat-icon {
   display: none;
 }

 .cursor-default {
   cursor: default !important
 }

 /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
 .mat-tab-label-active {
   font-weight: bold !important;

 }

 ::-webkit-scrollbar {
   width: 10px;
   height: 3px;

   &:hover {
     height: 6px;
   }
 }

 /* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: rgb(207, 207, 207);
 }

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: rgb(117, 117, 117);
 }

 .hideGoogleAutoComplete .pac-container {
   display: none !important;
 }

 .googleAutoCompleteEditMode .pac-container {
   position: fixed !important;
 }

 .lh-30 {
   line-height: 30px;
 }

 .btn {
   &:focus {
     box-shadow: none;
     outline: 0;
   }
 }

 @media print and (min-width: 20px) {
   body {
     background-color: #fff;
   }

   app-header,
   app-footer {
     display: none;
   }

   .no-print,
   .no-print * {
     display: none !important;
   }
 }

 @keyframes fadeOut {
   0% {
     opacity: 0;
     font-size: 1500%;
   }

   50% {
     opacity: 1;
     font-size: 1500%;
   }

   100% {
     opacity: 0;
     font-size: 100%;
   }
 }

 .fade-zoom-animation {
   display: block;
   -webkit-animation: fadeOut 4s;
   animation: fadeOut 4s;
   animation-fill-mode: forwards;
 }

 .mat-mdc-menu-item {
   height: auto !important;
 }

 // .sidebar-toggled {
 //   .dashboard {
 //     width: 100% !important;
 //   }

 //   #accordionSidebar {
 //     position: fixed;
 //     top: 0;
 //     left: 0;
 //     z-index: 999999;
 //   }
 // }

 .slider-horizontal {
   .tooltip.in {
     opacity: 1;
   }
 }

 table.mat-mdc-table {
   box-shadow: none !important;
   border: 1px solid #ccc;
 }

 .filter-bar {
   .slider.slider-horizontal {
     width: 94%;
   }
 }

 .mat-mdc-paginator-container {
   justify-content: flex-start !important;
 }

 .sliding-panel-wrapper>* {
   flex-basis: 300px;
   margin: 10px;
   transition: all 0.4s ease 0s;
 }

 .sidebar-toggled {
   #content-wrapper {
     &::after {
       display: none;
     }
   }
 }

 .user-menu {
   min-width: 185px !important;

   .mat-mdc-menu-item {
     width: 100%;
     height: auto;
     line-height: 30px !important;
   }
 }

 @media (max-width: 600px) {
   // #fc_frame {
   //  display: none;
   // }

   .fixed-spinner {
     left: 38%;
   }
 }

 .text-gray {
   color: $grayColor;
 }

 .bg-gray {
   background-color: $grayBG;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
 .mat-radio-outer-circle,
 .mat-checkbox-frame {
   border-color: $lightGray;
   box-shadow: $shadow-sm;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
 .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
   border-color: $pere-primary-color !important;
 }

 .mat-expansion-panel-header-description,
 .mat-expansion-indicator::after {
   color: #212529;
 }

 .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle,
 .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
 .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
 .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
   background-color: $pere-primary-color !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
 .mat-slide-toggle-thumb {
   background-color: $lightGray;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
 .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
   background-color: $pere-dark-color;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
 .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
   background-color: $pere-primary-color;
 }

 .mat-mdc-progress-spinner.mat-accent circle,
 .mat-mdc-progress-spinner.mat-accent circle {
   stroke: $pere-primary-color;
 }

 .text-underlined {
   text-decoration: underline !important;
 }

 .border-bottom-pere {
   border-bottom: 1px solid $pere-primary-color;
 }

 .btn-underlined {
   border: 0;
   border-bottom: 1px solid;
   border-color: $pere-primary-color;
   color: $pere-dark-color;
   padding-bottom: 0px;
   margin-bottom: 5px;
   padding-right: 0;
   padding-left: 0;
   border-radius: 0;
   font-weight: bold;
 }

 .mat-mdc-tab-group.mat-primary .mat-ink-bar,
 .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
   background-color: $pere-dark-color;
 }

 .shadow {
   box-shadow: $shadow !important;
 }

 .mat-mdc-button,
 .mat-mdc-icon-button,
 .mat-mdc-outlined-button,
 .mat-mdc-unelevated-button {
   min-height: 47px !important;
 }

 .btn-gray {
   background: $grayBG;
   border: 0 !important;
 }

 .mat-mdc-menu-panel {
   border: 1px solid $pere-primary-color;
 }

 .mat-mdc-dialog-container {
   border: 1px solid $pere-primary-color;
 }

 .mat-mdc-raised-button {
   min-height: 47px !important;
 }

 .copyRights {
   color: $pere-dark-color;
 }

 .on-dark-theme {
   display: none !important;
 }

 @media (min-width: 1600px) {
   .container-xxl {
     max-width: 1500px;
     margin-left: auto !important;
     margin-right: auto !important;
   }
 }

 @media (max-width: 1500px) {
   .container-xxl {
     width: 100%;
     padding-right: 15px;
     padding-left: 15px;
     margin-right: auto;
     margin-left: auto;
   }
 }

 .close-modal-btn {
   border: 0;
   background: none;
   position: absolute;
   right: 3px;
   top: 8px;
 }

 .mat-mdc-row:hover {
   background-color: #fdf5e7;
 }

 .mat-mdc-header-cell {
   color: $pere-dark-color;
   font-weight: bold;
 }

 .mat-sort-header-arrow {
   color: $pere-primary-color;
 }

 .booking-modal-header,
 .miss-restaurant-header {
   position: relative;
   top: 0px;
   max-height: 62px;
   text-align: left;
   background: $pere-dark-color;
   color: #fff;
   left: 0px;
   width: 100%;
   display: flex;
   align-items: center;
   padding-left: 15px;

   h5,
   h4,
   h3 {
     margin: 10px 40px 10px 0;
     color: #fff;
   }

   button {
     z-index: 9;
   }
 }

 .fc-widget-open {
   .fresh-chat-btn {
     app-spinner {
       display: none;
     }
   }
 }

 #content-wrapper {
   >#content {
     min-height: calc(100vh + 70px);
   }
 }

 .adjust-height {

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-appearance-outline .mat-form-field-outline {
     height: 47px;
   }

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-appearance-outline .mat-form-field-wrapper {
     margin-top: 0 !important;
   }

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-wrapper {
     padding-bottom: 0;
   }
 }

 /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
 .mat-mdc-unelevated-button.mat-primary.mat-button-disabled,
 .mat-mdc-raised-button.mat-primary.mat-button-disabled,
 .mat-mdc-fab.mat-primary.mat-button-disabled,
 .mat-mdc-mini-fab.mat-primary.mat-button-disabled {
   opacity: 0.7 !important;
   background-color: $pere-primary-color !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
 .mat-mdc-unelevated-button.mat-accent.mat-button-disabled,
 .mat-mdc-raised-button.mat-accent.mat-button-disabled,
 .mat-mdc-fab.mat-accent.mat-button-disabled,
 .mat-mdc-mini-fab.mat-accent.mat-button-disabled {
   opacity: 0.7 !important;
   background-color: $pere-secondary-color !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .mat-select-value {
   max-width: none !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 .mat-form-field-appearance-outline .mat-form-field-prefix {
   top: -0.15em !important;
 }

 .arrow-svg {
   vertical-align: super;
   cursor: pointer;
 }

 .text-underlined-white {
   position: relative;

   &:after {
     content: "";
     background-color: #fff;
     height: 4px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0;
   }
 }

 .text-underlined-pere {
   position: relative;

   &:after {
     content: "";
     background-color: $pere-primary-color;
     height: 4px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0;
   }

   &.sm {
     &:after {
       height: 3px;
       bottom: 0;
     }
   }
 }

 .block {
   .img-container {
     height: 100px;
     overflow: hidden;

     img {
       height: 100%;
     }
   }

   .desc {
     height: 80px;
     overflow: hidden;
   }
 }

 .toast-top-center {
   top: 10px !important;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .mat-mdc-select-arrow {
   display: none;
 }

 .mat-mdc-paginator {

   /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
   .mat-mdc-select-arrow {
     display: inline-block !important;
     margin: -3px -2px 0 0 !important;
   }
 }

 .mat-expansion-indicator {
   display: none;
 }

 .shrink-2x {
   transform: scale(0.7);
 }

 .mat-calendar-controls {
   .mat-mdc-icon-button {
     min-height: 0px;
   }
 }

 .introjs-nextbutton {
   font-weight: 700 !important;
 }

 .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
 .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
 .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
   border-color: $pere-dark-color;
 }

 .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
 .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
 .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
   border-color: $pere-primary-color;
   border-width: 2px;
 }

 .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch {
   border-left: none !important;
 }

 .mat-form-field-appearance-outline.mat-focused {

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-outline-thick {
     color: $pere-dark-color;
   }

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-label {
     color: $pere-dark-color;
   }
 }

 .mat-form-field-appearance-legacy.mat-focused {

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-label {
     color: $pere-dark-color;
   }
 }

 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 .mat-form-field-appearance-outline .mat-form-field-outline-thick {
   color: $pere-primary-color;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 .mat-mdc-form-field.mat-focused .mat-form-field-ripple {
   background-color: $pere-dark-color;
 }

 .cdk-virtual-scroll-spacer {
   z-index: 10000000;
 }

 @supports (-webkit-touch-callout: none) {
   /* CSS specific to iOS devices */
   // .cdk-virtual-scroll-spacer {
   //   height: 100vh !important;
   //   width: 15px !important;
   //   background-color: #ccc;
   // }

   .sort-fav-btn {
     display: none;
   }
 }

 [class$="parentgroup"]+g {
   display: none !important;

   g {
     fill: rgb(255 255 255) !important;
   }
 }

 .mat-step-header .mat-step-icon-selected {
   background-color: $pere-primary-color;
   color: #fff;
 }

 .mat-step-header .mat-step-icon-state-edit {
   background: url('assets/images/check-success-ico.png') no-repeat center;
   background-size: 60% 60%;
   background-color: $pere-dark-color;
 }

 .mat-step-icon .mat-icon {
   display: none;
 }

 .cursor-default {
   cursor: default !important
 }

 /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
 .mat-tab-label-active {
   font-weight: bold !important;

 }

 /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
 .mat-mdc-raised-button.mat-accent.mat-button-disabled {
   color: rgb(255 255 255 / 70%) !important;
 }

 .like-form-field {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;

   .label {

     p {
       margin: 0;
       padding: 0;
       line-height: 1;
     }
   }
 }

 .pere-box {
   overflow: hidden;
   box-shadow: $shadow-sm;
   border: 1px solid $pere-primary-color;
   padding: 15px;
 }

 .pere-border {
   border: 2px solid $pere-primary-color !important;
 }

 .w-30 {
   width: 30% !important;
 }

 .w-35 {
   width: 35% !important;
 }

 .fs-14 {
   font-size: 14px !important;
 }

 .fs-12 {
   font-size: 12px !important;
 }

 .date-form-field {

   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
   .mat-form-field-prefix {
     top: -9px !important;
   }
 }

 .mt-minus-sm {
   margin-top: -15px;
 }

 .not-bold {
   font-weight: normal;
 }

 .ng-image-slider .ng-image-slider-container .main .main-inner {
   width: 100% !important;
   margin: 0 !important;
 }

 .ng-image-slider .ng-image-slider-container .main {
   position: static !important;
 }

 .ng-image-fullscreen-view .lightbox-wrapper {
   background-color: transparent !important;
 }

 @media(min-width:1025px) {
   .ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .lightbox-image-main .lightbox-image .custom-image-main {
     display: flex;
     align-items: center;
     justify-content: center;
   }

   .ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .lightbox-image-main .lightbox-image .custom-image-main iframe,
   .ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .lightbox-image-main .lightbox-image .custom-image-main video {
     width: 50% !important;
     height: 50% !important;
   }
 }

 .ng-image-fullscreen-view .lightbox-wrapper .close {
   background-image: url('./assets/images/close-btn-pere.svg') !important;
   background-color: transparent !important;
 }

 .ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .next,
 .ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .prev {
   background-color: transparent !important;
   font-size: 75px !important;
   color: #E8A014 !important;
 }

 .lh-0 {
   line-height: 0 !important;
 }

 .ng-image-slider .ng-image-slider-container .main .main-inner .img-div {
   width: 205px !important;
   height: 205px !important;
   margin-bottom: 50px !important;
 }

 .h-dots {
   cursor: pointer;
   margin-top: -5px;

   &:hover {
     // color: $pere-primary-color;
     opacity: 0.7;

     &::after {
       font-size: 28px;
       transition: 0.3s all ease ease;
     }
   }
 }

 .h-dots:after {
   content: '\2026';
   font-size: 25px;

 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 table .mat-select-disabled .mat-select-value {
   color: #1a1a1a !important;
 }

 // .toast-top-right{
 //   top: 98px !important;
 //   right: 101px !important;
 // }

 // .toast-message a {
 //   color: #000 !important;
 // }


 .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
 .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
   border-color: $pere-dark-color;
 }

 .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
 .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
   color: #000;
   font-weight: bold;
 }

 .mat-mdc-select-arrow-wrapper {
   display: none !important;
 }

 .mat-mdc-form-field-icon-suffix {
   padding: 0 10px 0 4px !important;
 }


 .mdc-text-field--filled:not(.mdc-text-field--disabled) {
   background-color: transparent !important;
 }

 .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
   background-color: $pere-dark-color !important;
 }

 .mdc-switch__icons {
   display: none !important;
 }

 .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
   background-color: $pere-primary-color !important;
 }

 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
   background-color: $pere-dark-color !important;
   border-color: $pere-dark-color !important;
 }

 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
   color: $pere-primary-color !important;
 }

 .mat-mdc-form-field-icon-prefix,
 [dir=rtl] .mat-mdc-form-field-icon-suffix {
   padding: 0px 5px !important;
   margin-left: 5px !important;
 }

 .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
   color: $pere-dark-color !important;
 }

 .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
   border-color: $pere-primary-color !important;
 }

 .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
   border-color: $pere-primary-color !important;
 }

 .mat-mdc-radio-button .mdc-radio {
   padding: 5px !important;
 }

 //  .mat-mdc-form-field-icon-prefix, [dir=rtl] .mat-mdc-form-field-icon-suffix

 .mdc-button {
   letter-spacing: 0 !important;
 }

 .mat-mdc-dialog-container .mdc-dialog__content {
   letter-spacing: 0px !important;
 }

 .mdc-dialog__content {
   overflow: unset !important;
   overflow-y: unset !important;
 }


 .mdc-text-field--filled .mdc-line-ripple::after {
   border-bottom-color: $pere-primary-color !important;
 }


 .mdc-tab,
 .mdc-form-field,
 .mat-mdc-form-field,
 .mat-mdc-floating-label,
 .mat-mdc-select,
 .mat-mdc-menu-content,
 .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
   letter-spacing: normal !important;
   font-size: 14px !important;
 }

 .mat-mdc-option .mdc-list-item__primary-text {
   font-size: 14px !important;
 }


 .mat-mdc-text-field-wrapper:not(:has(textarea)) {
   max-height: 52px !important;
 }



 .for-text-area {
   .mat-mdc-text-field-wrapper {
     max-height:  fit-content !important;
   }
 }

 .mat-mdc-tab-nav-bar,
 .mat-mdc-tab-header {
   border-bottom: 1px solid #1211111f;
 }

 .mat-mdc-checkbox .mdc-checkbox {
   padding: 5px !important;
 }

 .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
   width: 40px !important;
   height: 40px !important;
   top: 5px !important;
   left: 4px !important;
 }

 .mat-mdc-checkbox-touch-target {
   top: 67% !important;
   height: 35px !important;
   left: 66% !important;
   width: 35px !important;
 }

 .mat-mdc-checkbox-ripple,
 .mdc-checkbox__ripple {
   top: -2px !important;
   left: -2px !important;
   right: -2px !important;
   bottom: -2px !important;
 }

 .mdc-checkbox__background {
   top: unset !important;
   left: unset !important;
 }

 .mat-mdc-menu-item {
   min-height: 30px !important;
 }

 .mat-mdc-option {
   font-size: 14px !important;
 }

 .mat-mdc-form-field {
   margin-bottom: 15px;
 }


 .table-responsive {
   overflow-wrap: anywhere;
 }



 //  i.fas {
 //   font-family: 'Font Awesome 5 Free' !important;
 //  }

 // 