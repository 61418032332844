.bg-gradient-primary {
  background-color: #4e73df;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #4e73df), to(#224abe));
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover
}

.bg-gradient-secondary {
  background-color: #858796;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #858796), to(#60616f));
  background-image: linear-gradient(180deg, #858796 10%, #60616f 100%);
  background-size: cover
}

.bg-gradient-success {
  background-color: #1cc88a;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #1cc88a), to(#13855c));
  background-image: linear-gradient(180deg, #1cc88a 10%, #13855c 100%);
  background-size: cover
}

.bg-gradient-info {
  background-color: #36b9cc;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #36b9cc), to(#258391));
  background-image: linear-gradient(180deg, #36b9cc 10%, #258391 100%);
  background-size: cover
}

.bg-gradient-warning {
  background-color: #f6c23e;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #f6c23e), to(#dda20a));
  background-image: linear-gradient(180deg, #f6c23e 10%, #dda20a 100%);
  background-size: cover
}

.bg-gradient-danger {
  background-color: #e74a3b;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #e74a3b), to(#be2617));
  background-image: linear-gradient(180deg, #e74a3b 10%, #be2617 100%);
  background-size: cover
}

.bg-gradient-light {
  background-color: #f0f0f0;
  /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #f0f0f0), to(#e4e4e4));
  background-image: linear-gradient(180deg, #f0f0f0 10%, #c2cbe5 100%);
  background-size: cover */
}

.bg-gradient-dark {
  background-color: #5a5c69;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #5a5c69), to(#373840));
  background-image: linear-gradient(180deg, #5a5c69 10%, #373840 100%);
  background-size: cover
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image img {
  height: 2.5rem;
  width: 2.5rem
}

#wrapper #content-wrapper {
  background-color: #f8f9fc;
  padding: 40px 60px;

}

.text-gray-800 {
  color: #8a8b92 !important;
}

.mat-mdc-dialog-actions {
  padding: 0 !important;
  margin-bottom: 0 !important;
  justify-content: center;
}

.sidebar {
  width: 265px;
  min-height: 100vh;
}

#content-wrapper {
  /* background-color: #f8f9fc; */
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 110px;
}

#wrapper #content-wrapper #content {
  flex: 1 0 auto;
}
