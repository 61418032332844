@import '../scss/variables.scss';

.introjs-helperNumberLayer {
  font-family: "proxima-nova", sans-serif;
  font-size: 14px;
  text-shadow: none;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border: 2px solid #ecf0f1;
  border-radius: 50%;
  background: #e74c3c;
}

.introjs-helperLayer {
  background-color: #FFF;
  background-color: rgba(255, 255, 255, .9);
  border: none;
  border-radius: 2px;
}

.introjs-tooltip {
  letter-spacing: .1em;
  color: #2c3e50;
  font-family: "proxima-nova", sans-serif;
  border-radius: 2px;
}


.introjs-prevbutton {
  color: $pere-dark-color !important;
  background: #eae9e9;
  line-height: 25px;
  border: 0;
  min-width: 35px;
  text-align: center !important;

}


.introjs-skipbutton {
  background: none !important;
  border: 0 !important;
}

.introjs-nextbutton,
.introjs-nextbutton:hover,
.introjs-nextbutton:focus {
  background: $pere-primary-color;
  font-weight: normal;
  border: 0px;
  color: $pere-dark-color !important;
  box-shadow: none;
  text-shadow: none !important;
  opacity: 1 !important;
  line-height: 25px;
  letter-spacing: .5px;
  min-width: 35px;
  text-align: center !important;

}

.introjs-progressbar {
  background: #d4d4d4;
}

/* welcome modal*/
/*.cdk-overlay-0 */

/* .mat-dialog-container{padding:0 !important;} */
.onboarding-welcome {
  height: 120px;
  background-color: #2e2a26;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.onboarding-welcome img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.onboarding-welcome-container h1 {
  padding: 10px 20px 0 20px;
  border: solid 1 px black;
}

.onboarding-welcome-container .welcome-text {
  padding: 0 20px 0 20px;
}

.introjs-fixParent {
  position: absolute !important;
}